.bannerItem {
  width: calc(50% - 40px);
  padding-left: 40px;
  height: 100%;

  .bannerImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 32px;
  }
}
@media only screen and (max-width: 800px) {
  .bannerItem {
    display: none;
    width: calc(50% - 10px);
    padding-left: 10px;
    height: 100%;

    .bannerImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 32px;
    }
  }
}
