.MapWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  .Map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .TextWrapper {
      background-color: yellowgreen;
      border-radius: 10px;
      padding: 5px;
      text-align: center;
    }
  }
}
