.contentItem {
  width: 100%;
  max-width: calc((100% / 3) - 50px);
  margin: 0 25px;
  margin-bottom: 100px;

  .imageWrapper {
    height: 360px;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    border-radius: 16px;
    margin-bottom: 24px;

    .contentImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.4s ease-in-out;
    }

    &:hover {
      .contentImage {
        transform: scale(1.05);
      }
    }
  }

  .contentItemTitle {
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .contentInfo {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .prizeWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    font-size: 14px;

    .currency {
      font-weight: bold;
      font-size: 16px;
      padding-right: 8px;
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    user-select: none;
    justify-content: space-between;

    .buttonWrapper {
      width: 40%;
      .btn {
        text-align: center;
        box-sizing: content-box;
        width: 100%;
      }
    }

    .btn2 {
      box-sizing: content-box;
      width: 40%;
    }
  }
}

@media only screen and (max-width: 800px) {
  .contentItem {
    width: calc(100% - 20px);
    max-width: unset;
    margin: 0 10px;
    margin-bottom: 50px;

    .imageWrapper {
      width: 98%;
      height: 300px;
      cursor: pointer;
      overflow: hidden;
      border-radius: 16px;
      margin-bottom: 24px;

      .contentImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.4s ease-in-out;
      }

      &:hover {
        .contentImage {
          transform: scale(1.05);
        }
      }
    }

    .contentItemTitle {
      margin: 0;
      padding: 0;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 16px;
    }

    .contentInfo {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    .prizeWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      font-size: 14px;

      .currency {
        font-weight: bold;
        font-size: 16px;
        padding-right: 8px;
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      user-select: none;
      justify-content: space-between;

      .buttonWrapper {
        width: 40%;
        .btn {
          text-align: center;
          box-sizing: content-box;
          width: 100%;
        }
      }

      .btn2 {
        box-sizing: content-box;
        width: 40%;
      }
    }
  }
}
