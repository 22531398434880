.modal {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    height: 80%;
    width: 40%;
    background: white;
    border-radius: 40px;
    overflow: hidden;
    padding: 1% 2%;

    .img {
      width: 100%;
      height: 40%;
      display: flex;
      justify-items: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        margin: 10% 20px;
        border-radius: 15px;
      }
    }

    .title {
      font-size: 5vh;
      margin-bottom: 10px;
      width: 100%;
      text-align: center;
    }

    .desc {
      font-size: 2vh;
      height: 25%;
      overflow: auto;
      margin-bottom: 10px;
    }
  }
  .btn {
    margin: 25px auto;
  }
}

@media only screen and (max-width: 800px) {
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      height: 85%;
      width: 80%;
      background: white;
      border-radius: 20px;
      overflow: hidden;
      padding: 3% 5%;

      .img {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
          margin: 20px 5%;
          border-radius: 15px;
        }
      }

      .title {
        font-size: 4vh;
        margin-bottom: 20px;
        margin-top: 10px;
      }

      .desc {
        font-size: 2vh;
        margin-bottom: 10px;
      }
    }
  }
}
