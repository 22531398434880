// we use each platform native system fonts
// https://css-tricks.com/snippets/css/system-font-stack/
html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  -webkit-font-smoothing: subpixel-antialiased;
}


body {
  height: 100vh;
  user-select: none;

  #root {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
  }
}
