.bannerSection {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 800px) {
  .bannerSection {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
    margin-bottom: 40px;
  }
}
