@import "../../../styles/variables.scss";

.bannerInfo {
  width: calc(50% - 20px);
  padding-right: 20px;

  .bannerTitle {
    line-height: 80px;
    font-size: 48px;
    font-weight: 900;
    text-align: left;
    margin-bottom: 34px;

    span {
      position: relative;

      &::after {
        position: absolute;
        display: block;
        content: "";
        height: 8px;
        width: 100%;
        left: 0;
        bottom: 5px;
        z-index: -1;
      }

      &.varA::after {
        background: $decoration-a;
      }

      &.varB::after {
        background: $decoration-b;
      }

      &.varC::after {
        background: $decoration-c;
      }
    }
  }

  .bannerText {
    font-size: 24px;
    font-weight: 500;
    line-height: 35px;
    margin-bottom: 45px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;

    .large {
      padding: 20px;
      font-size: 16px;

      &:nth-child(1) {
        margin-right: 16px;
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .bannerInfo {
    width: calc(100% - 20px);
    padding-right: 20px;

    .bannerTitle {
      line-height: 35px;
      font-size: 29px;
      font-weight: 900;
      text-align: left;
      margin-bottom: 20px;

      span {
        position: relative;

        &::after {
          position: absolute;
          display: block;
          content: "";
          height: 8px;
          width: 100%;
          left: 0;
          bottom: 5px;
          z-index: -1;
        }

        &.varA::after {
          background: $decoration-a;
        }

        &.varB::after {
          background: $decoration-b;
        }

        &.varC::after {
          background: $decoration-c;
        }
      }
    }

    .bannerText {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 30px;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .large {
        padding: 20px;
        font-size: 16px;

        &:nth-child(1) {
          margin-right: 16px;
        }
      }
    }
  }
}
