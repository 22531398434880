.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0;
  margin-bottom: 80px;

  .logo {
    display: flex;
    align-items: center;
    margin-left: 10px;

    .image {
      margin-right: 8px;
      height: 30px;
      width: 30px;
      background-size: contain;
    }

    .title {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .contact {
    .callBtn {
      display: flex;
      align-items: center;
      border-radius: 25px;
      padding: 12px 16px;
      background-color: #3c61ff;
      text-decoration: none;
      color: white;
      font-weight: bolder;
      transition: 0.5s ease-in-out;
      margin-right: 10px;

      .icon {
        background-size: contain;
        height: 14px;
        width: 14px;
      }

      .btnText {
        margin-left: 8px;
        font-size: 12px;
      }

      &:hover {
        background-color: darken($color: #007bff, $amount: 16);
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 8px;
    margin-bottom: 40px;

    .logo {
      display: flex;
      align-items: center;

      .image {
        margin-right: 8px;
        height: 30px;
        width: 30px;
        background-size: contain;
      }

      .title {
        font-weight: bold;
      }
    }

    .contact {
      display: flex;
      flex-direction: row;
      .callBtn {
        display: flex;

        align-items: center;
        border-radius: 25px;
        padding: 12px 16px;
        background-color: #3c61ff;
        text-decoration: none;
        color: white;
        font-weight: bolder;
        transition: 0.5s ease-in-out;

        .icon {
          background-size: contain;
          height: 14px;
          width: 14px;
        }

        .btnText {
          margin-left: 8px;
          font-size: 12px;
        }

        &:hover {
          background-color: darken($color: #007bff, $amount: 16);
        }
      }
    }
  }
}
