.content {
  display: flex;
  flex-wrap: wrap;
  .contentTitle {
    width: 100%;
    text-align: center;
    margin-bottom: 100px;
    font-size: 36px;
    font-weight: 800;
  }
}
@media only screen and (max-width: 800px) {
  .content {
    display: flex;
    align-items: unset;
    flex-direction: column;
    .contentTitle {
      width: 100%;
      text-align: center;
      margin-bottom: 50px;
      font-size: 36px;
      font-weight: 800;
    }
  }
}
