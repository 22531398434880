@import "../../styles/variables.scss";

.btn {
  font-weight: bold;
  padding: 12px;
  font-size: 12px;
  text-align: center;
  width: 50%;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.265s ease-in-out;

  &.primary {
    background-color: $primary-color;
    color: white;
  }

  &.secondary {
    background-color: $secondary-color;
  }

  &:hover {
    transform: scale(0.98);
  }
}
