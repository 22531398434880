@import "../../styles/variables.scss";

.offer {
  background-color: $secondary-color;
  text-align: center;
  border-radius: 20px;
  margin-bottom: 130px;
  padding: 50px;

  .title {
    padding: 0;
    margin: 0;
    font-size: 36px;
    margin-bottom: 20px;
    line-height: 64px;
  }

  .text {
    font-size: 24px;
    margin-bottom: 24px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    .topWrapper {
      display: flex;
      flex-direction: row;
      .nameWrapper {
        width: 50%;
        input {
          margin: 10px;
          width: 70%;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid gray;
        }
      }
      .surnameWrapper {
        width: 50%;
        input {
          margin: 10px;
          width: 70%;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid gray;
        }
      }
    }
    .bottomWrapper {
      display: flex;
      flex-direction: row;
      .numberWrapper {
        width: 50%;
        input {
          margin: 10px;
          width: 70%;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid gray;
        }
      }
      .mailWrapper {
        width: 50%;
        input {
          margin: 10px;
          width: 70%;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid gray;
        }
      }
    }
    .messageWrapper {
      width: 100%;
      textarea {
        margin: 10px;
        width: 85%;
        height: 20vh;
        resize: none;
        border: 1px solid gray;
        border-radius: 5px;
      }
    }
  }

  .error {
    margin-bottom: 10px;
  }
  //todo
  .btn {
    width: unset;
    display: inline-block;
  }
}

@media only screen and (max-width: 800px) {
  .offer {
    background-color: $secondary-color;
    text-align: center;
    border-radius: 20px;
    margin-bottom: 130px;
    padding: 50px;

    .title {
      padding: 0;
      margin: 0;
      font-size: 36px;
      margin-bottom: 20px;
      line-height: 64px;
    }

    .text {
      font-size: 24px;
      margin-bottom: 24px;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      .topWrapper {
        display: flex;
        flex-direction: column;
        .nameWrapper {
          width: 100%;
          input {
            margin: 10px;
            width: 85%;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid gray;
          }
        }
        .surnameWrapper {
          width: 100%;
          input {
            margin: 10px;
            width: 85%;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid gray;
          }
        }
      }
      .bottomWrapper {
        display: flex;
        flex-direction: column;
        .numberWrapper {
          width: 100%;
          input {
            margin: 10px;
            width: 85%;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid gray;
          }
        }
        .mailWrapper {
          width: 100%;
          input {
            margin: 10px;
            width: 85%;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid gray;
          }
        }
      }
      .messageWrapper {
        width: 100%;
        textarea {
          margin: 10px;
          width: 85%;
          height: 20vh;
          resize: none;
          border: 1px solid gray;
          border-radius: 5px;
        }
      }
    }

    .error {
      margin-bottom: 10px;
    }
    //todo
    .btn {
      width: unset;
      display: inline-block;
    }
  }
}
